<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>الصرف الصناعي</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <v-card class="changeTitleStyle mb-3">
              <v-card-title class="changeTitleStyle">
                <h3>نبذه عن الصرف الصناعي</h3>
              </v-card-title>
              <v-card-text class="importantSite">
                <h3>
                  مع التقدم الصناعي لكثير من دول العالم وازدياد عدد المنشأت
                  الصناعية في معظم دول العالم النامي والمتقدم ، ظهرت مشكلة
                  التخلص من المخلفات الصناعية السائلة الناتجة عن النشاط الصناعي
                  ، واتجهت كثير من الشركات الصناعية الي التخلص من مخلفاتها
                  السائلة بصرفها الي المجاري العمومية ( شبكات الصرف الصحي )
                  ولذلك فمن الضروري مراقبة عمليات صرف أية مخلفات صناعية وألا
                  تسمح السلطات الرقابية بصرف أية مياه صرف صناعية على الشبكة
                  العمومية قبل معرفة كافة خصائص هذه المياه ومدى قدرة شبكة الصرف
                  علي استيعابها ، بالاضافة الى معرفة تأثير ومدى خطورة صرف
                  المركبات المختلفة الموجودة في هذه المياه على شبكات الصرف الصحي
                  وذلك علي المدى القريب والمدى البعيد
                </h3>
                <br />
                <h3>
                  إن اهتمام المجتمع الحقيقي بالبيئة على المدى الطويل مطلوب
                  لتحقيق تغيير في مفهوم العامة للحفاظ على البيئة من أجل التنمية
                  المستدامة
                </h3>
                <br />
                <h3>
                  ومياه الصرف الصناعي يختلف وضعها من صناعة لأخرى نتيجة لاختلاف
                  المواد الأولية اللازمة للصناعة والمواد الناتجة أو المصنعة
                </h3>
                <br />
                <h3>
                  إن التصنيف النهائى للملوثات من حيث كونها مطابقة أو غير مطابقة
                  يعتمد علي دراسة نظام الصرف الصحى( محطات المعالجة وشبكات الصرف
                  الصحي ).
                </h3>
              </v-card-text>
            </v-card>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in ArrayOFAnElsherka"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-header class="fade-in">
                  {{ item.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h4 class="importantSite" v-if="item.text">
                    {{ item.text }}
                  </h4>
                  <ul v-if="item.subtext">
                    <li v-for="i in item.subtext" :key="i">
                      <h3>
                        {{ i.subTitle }}
                      </h3>
                    </li>
                  </ul>
                  <h4 class="brown--text" v-if="item.text3">
                    {{ item.text3 }}
                  </h4>
                  <h4 v-if="item.text4">{{ item.text4 }}</h4>
                  <br />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  components: { Link },
  data() {
    return {
      ArrayOFAnElsherka: [
        {
          title: "محطات المعالجه",
          text:
            "باختصار يتم في المدن والقرى تجميع مياه الصرف الصحي في خطوط يتم توجيهها الى محطات المعالجة التي هي على نوعين :",
          subtext: [
            {
              id: "1",
              subTitle: "المحطات المفتوحة ",
              subtext:
                " تدخل فيها المياه في عدة مراحل من أحواض ترسيب وتجميع وتصفية لفصل المواد المحملة بالمياه واستبعاد مالايمكن الاستفادة منه وترسيب الباقي لاستخدامه كسماد وكذلك استخدام المياه المصفاة واعادة توجيهها لاقنية الري وعيوب هذه الطريقة الروائح الكريهة التي تصدر منها على الجوار وحاجتها لمساحات كبيرة ( حسب حجم المياه المصصممة لاستيعابها ) وبالتالي يجب أن تكون بعيدة ماأمكن عن المجمعات السكنية ",
            },
            {
              id: "2",
              subTitle: "الطريقة المغلقة ",
              subtext:
                " لاتختلف آلية المعالجة كثيراً عن النظام السابق غير أنها مغلقة لايشعر أحد بوجودها لانها محكمة الاغلاق ويمكن تنفيذها في حديقة عامة مثلاً .",
            },
          ],
          text4:
            "للنظامين الاحجام المناسبة والتصميم المناسب حسب كميات المياه المطلوب معالجتها يومياً .",
        },
        {
          title: "الملوثات فى مياه الصرف الصناعي ",
          text:
            "المواد الصلبة العالقة – المواد المغذية – الملوثات ذات الأهمية القصوى – المواد العضوية صعبة التحلل- المعادن الثقيلة – الأملاح الغير العضوية الذائبة",
        },
        {
          title: "الطرق الشائعة لمعالجة مياه الصرف الصناعي",
          subtext: [
            {
              id: "1",
              subTitle: "عمليات المعالجة الفيزيائية ",
            },
            {
              id: "2",
              subTitle: "عمليات المعالجة البيولوجية",
            },
            {
              id: "3",
              subTitle: "عمليات المعالجة الكيميائية",
            },
          ],
          text4:
            "إن دور الإدارة العامة للصرف الصناعي بشركة مياه الشرب والصرف الصحي بأسيوط  والوادي الجديد  لا يقتصر على الإشراف على المنشآت بجميع أنواعها ( صناعية – تجارية – سياحية – طبية  ،،،،،،،،،،)ولكن أيضا يكمن في الحفاظ على شبكات الصرف الصحى من أجل غدا أفضل",
        },
      ],
    };
  },
};
</script>
<style>
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
</style>
